<template>
  <div id="HoraryRegister">
    <button
      @click="addHorary()"
      style="background-color: #1d425e; color: white"
      class="btn mb-3"
    >
      Agregar horario
    </button>
    <div>
      <b-modal scrollable centered v-model="show" title="Registra Horario">
        <b-container fluid>
          <!--Contenido modal-->
          <AlertError ref="error" />
          <form>
            <div class="row">
              <div class="col-6">
                <div class="form-group">
                  <label><b>Seleccionar día:</b></label>
                  <b-form-select
                    :state="dataSend.day != null"
                    v-model="dataSend.day"
                    :options="options"
                    required
                  ></b-form-select>
                </div>
              </div>
              <div class="col-6"></div>
            </div>
            <div class="row">
              <div class="col-6">
                <div class="form-group">
                  <label><b>Hora de inicio:</b></label>
                  <b-form-timepicker
                    :state="dataSend.timeStart != null"
                    v-model="dataSend.timeStart"
                    locale="en"
                  ></b-form-timepicker>
                </div>
              </div>
              <div class="col-6">
                <div class="form-group">
                  <label><b>Hora de fin:</b></label>
                  <b-form-timepicker
                    :state="dataSend.timeFinal != null"
                    v-model="dataSend.timeFinal"
                    locale="en"
                  ></b-form-timepicker>
                </div>
              </div>
            </div>
          </form>
        </b-container>

        <template #modal-footer>
          <div class="w-100">
            <button
              type="button"
              @click="saveAddItem"
              style="background-color: #1d425e; border: none; color: white"
              class="btn form-control my-2"
            >
              Enviar
            </button>
            <button
              type="button"
              @click="exit()"
              style="
                background-color: rgb(119, 35, 45);
                border: none;
                color: white;
              "
              class="btn form-control"
            >
              Cancelar
            </button>
          </div>
        </template>
      </b-modal>
    </div>
  </div>
</template>

<script>
import AlertError from "../../components/AlertError.vue";
export default {
  name: "HoraryRegister",
  components: { AlertError },
  props: {
    idCourse: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      show: false,
      dataSend: {},
      options: [
        { text: "Lunes", value: "L" },
        { text: "Martes", value: "M" },
        { text: "Miércoles", value: "R" },
        { text: "Jueves", value: "J" },
        { text: "Viernes", value: "V" },
        { text: "Sábado", value: "S" },
        { text: "Domingo", value: "D" },
      ],
    };
  },
  methods: {
    async saveAddItem() {
      if (
        this.dataSend.day == null ||
        this.dataSend.timeStart == null ||
        this.dataSend.timeFinal == null
      ) {
        this.$refs.error.showAlert("Ingrese todos los datos");
        return;
      }
      //console.log(JSON.stringify(this.dataSend, undefined, "\t"));
      await this.$axios
        .post(
          "/academic/course/" + this.idCourse + "/horary-register",
          this.dataSend,
          {
            headers: {
              Authorization: `Bearer ${localStorage.token}`,
            },
          }
        )
        .then((res) => {
          this.show = false;
          this.$message.success(res.data.content);
          this.$parent.initialize();
        })
        .catch((e) => {
          console.log(e);
          this.$refs.error.showAlert(e.response.data.content);
        });
    },
    addHorary() {
      this.show = true;
      this.dataSend = {};
    },
    exit() {
      this.show = false;
    },
  },
};
</script>
<style>
.modal-content {
  background-color: rgb(243, 243, 243);
}
</style>