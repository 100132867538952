<template>
  <div id="CoursesHorary">
    <div class="container" style="min-height: 150px">
      <div class="card">
        <div class="card-header">
          <div class="row">
            <div class="col-11">
              <h1>
                <b-icon icon="card-checklist" /> Horario de {{ desserts.name }}
              </h1>
            </div>
            <div class="col-1">
              <h1>
                <b-icon
                  type="button"
                  @click="toCourse"
                  icon="arrow-return-left"
                />
              </h1>
            </div>
          </div>
        </div>
        <div class="card-body">
          <b-row>
            <b-col><HoraryRegister :idCourse="id" /></b-col>
            <b-col> </b-col>
          </b-row>
          <div v-if="desserts.list.length != 0">
            <b-table
              striped
              responsive
              stacked="lg"
              :items="desserts.list"
              :fields="fields"
            >
              <template #cell(generate)="row">
                <div v-if="row.item.containsDates">
                  <b-badge variant="success">Fechas generadas</b-badge>
                  <b-icon-arrow-clockwise
                    @click="startGenerateDates(row.item.id)"
                    style="cursor: pointer"
                    class="ml-2"
                  />
                </div>
                <div v-else>
                  <b-button
                    class="border border-dark"
                    v-b-popover.hover.rigth="
                      'Esta función generara fechas segun el dia para el cronograma de asistencias.'
                    "
                    size="sm"
                    variant="warning"
                    @click="startGenerateDates(row.item.id)"
                    >Generar fechas</b-button
                  >
                </div>
              </template>
              <template #cell(actions)="row">
                <b-icon-trash
                  type="button"
                  size="sm"
                  class="mx-1"
                  @click="modalDelete(row.item)"
                ></b-icon-trash>
              </template>
            </b-table>
          </div>

          <div v-else>
            <hr />
            <em><p class="text-center">No hay datos en esta tabla</p></em>
          </div>
        </div>
      </div>
      <!--Mensaje alerta eliminacion-->

      <div>
        <b-modal centered v-model="showDelete" title="Mensaje">
          <b-container fluid>
            <!--Contenido modal-->
            <h5 class="text-center">¿Seguro que desea eliminar la hora?</h5>
          </b-container>

          <template #modal-footer>
            <div class="w-100">
              <button
                type="button"
                @click="deleteItem(dataDelete)"
                style="background-color: #1d425e; border: none; color: white"
                class="btn form-control my-2"
              >
                Aceptar
              </button>
              <button
                type="button"
                @click="exitDelete()"
                style="
                  background-color: rgb(119, 35, 45);
                  border: none;
                  color: white;
                "
                class="btn form-control"
              >
                Cancelar
              </button>
            </div>
          </template>
        </b-modal>
      </div>
      <!--Modal generar fechas-->
      <b-modal
        scrollable
        centered
        v-model="showGenerateDates"
        title="Generar fechas"
      >
        <b-container fluid>
          <!--Contenido modal-->
          <form>
            <AlertError ref="error" />
            <div class="form-group">
              <label><b>Fecha de inicio:</b></label>
              <b-input
                :state="dataGenerate.startDate != ''"
                v-model="dataGenerate.startDate"
                type="date"
              />
            </div>
            <div class="form-group">
              <label><b>Fecha de fin:</b></label>
              <b-input
                :state="dataGenerate.endDate != ''"
                v-model="dataGenerate.endDate"
                type="date"
              />
            </div>
          </form>
        </b-container>

        <template #modal-footer>
          <div class="w-100">
            <button
              type="button"
              @click="generateDates()"
              style="background-color: #1d425e; border: none; color: white"
              class="btn form-control my-2"
            >
              Enviar
            </button>
            <button
              type="button"
              @click="exitGenerateDates()"
              style="
                background-color: rgb(119, 35, 45);
                border: none;
                color: white;
              "
              class="btn form-control"
            >
              Cancelar
            </button>
          </div>
        </template>
      </b-modal>
      <!---->
    </div>
  </div>
</template>

<script>
import HoraryRegister from "./Courses-horary-register.vue";
import AlertError from "../../components/AlertError.vue";
export default {
  name: "CoursesHorary",
  components: { HoraryRegister, AlertError },
  props: {
    id: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      dataDelete: "",
      data: {},
      dataGenerate: { startDate: "", endDate: "" },
      showDelete: false,
      showGenerateDates: false,
      rowId: null,
      desserts: { list: [] },
      fields: [
        { key: "day", label: "Dia" },
        { key: "startTime", label: "Hora de inicio" },
        { key: "finalTime", label: "Hora de fin" },
        { key: "generate", label: "Generación" },
        { key: "actions", label: "Acciones" },
      ],
    };
  },
  created() {
    this.initialize();
  },
  methods: {
    async generateDates() {
      await this.$axios
        .post(
          "/academic/horary/" + this.rowId + "/all-date-register",
          this.dataGenerate,
          {
            headers: {
              Authorization: `Bearer ${localStorage.token}`,
            },
          }
        )
        .then((res) => {
          this.$message.success(res.data.content);
          this.initialize();
          this.showGenerateDates = false;
        })
        .catch((error) => {
          this.showGenerateDates = false;
          this.$errorQuery(error);
        });
    },
    async startGenerateDates(id) {
      this.rowId = id;
      this.showGenerateDates = true;
      this.dataGenerate = { startDate: "", endDate: "" };
      await this.$axios
        .get("/academic/course/" + this.id + "/schedule", {
          headers: {
            Authorization: `Bearer ${localStorage.token}`,
          },
        })
        .then((res) => {
          this.dataGenerate.startDate = res.data.startDate;
          this.dataGenerate.endDate = res.data.endDate;
        })
        .catch((error) => {
          this.$errorQuery(error);
        });
    },
    async deleteItem(item) {
      this.showDelete = false;
      if (item != "") {
        try {
          await this.$axios
            .get(
              "/academic/course/" + this.id + "/horary-delete?id=" + item.id,
              {
                headers: {
                  Authorization: `Bearer ${localStorage.token}`,
                },
              }
            )
            .then((res) => {
              this.$message.success(res.data.content);
              this.initialize();
            });
        } catch (e) {
          this.$message.error(e.response.data.content);
          console.log(e);
        }
      }
    },
    exitDelete() {
      this.showDelete = false;
    },
    exitGenerateDates() {
      this.showGenerateDates = false;
    },
    modalDelete(data) {
      this.showDelete = true;
      this.dataDelete = data;
      this.data.name = data.name;
    },
    toCourse() {
      this.$router.go(-1);
    },
    async initialize() {
      await this.$axios
        .get("/academic/course/" + this.id + "/horary", {
          headers: {
            Authorization: `Bearer ${localStorage.token}`,
          },
        })
        .then((res) => {
          this.desserts = res.data;
        })
        .catch((error) => {
          this.$errorQuery(error);
        });
    },
  },
};
</script>
<style>
.card-header {
  background-color: rgb(119, 35, 45);
  color: white;
}
</style>